import { adminClient } from "@api/admin/AdminClient";
import StatsGraphTableComponent from "@components/admin/stats/StatsGraphTableComponent";
import { queryApi } from "@hooks/api";
import { Box, Table, TableBody, TableHead, TableRow } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { AdminItemSoldByDateResponse, AdminTotalItemCountResponse } from "@reshopper/admin-client";


export default function ItemsOverviewPageStatsComponent(props: { country: string }): JSX.Element {
	const [stats] = queryApi(
		async (options) => await adminClient().adminItemsItemOverviewStatsGet({
			...options,
			country: props.country === "all" ? undefined : props.country
		}),
		[props.country]);

		const [totalValueItemsKids] = queryApi(
			async (options) => await adminClient().adminItemsTotalPriceInHundredsForSaleGet({
				...options,
				country: props.country === "all" ? undefined : props.country,
			itemSegment: "kids"
			}),
			[props.country]);
			
			const [totalValueItemsWomen] = queryApi(
				async (options) => await adminClient().adminItemsTotalPriceInHundredsForSaleGet({
					...options,
					country: props.country === "all" ? undefined : props.country,

				itemSegment: "women"
				}),
				[props.country]);

				const [totalValueItemsHome] = queryApi(
					async (options) => await adminClient().adminItemsTotalPriceInHundredsForSaleGet({
						...options,
						country: props.country === "all" ? undefined : props.country,

					itemSegment: "home"
					}),
					[props.country]);

	function tableSkeleton(): JSX.Element[] {
		let elements = []

		for (let index = 0; index < 4; index++) {
			elements.push(
				<TableRow key={index}>
					<Skeleton height={60} width={200} component="td" />
				</TableRow>
			)
		}
		return elements;
	}

	function RenderSkeleton() {
		return <Box width="30%" mx={3}>
			<Table>
				<TableHead>
					<TableRow>
						<Skeleton height={80} width={200} component="td" />
					</TableRow>
				</TableHead>
				<TableBody>
					{tableSkeleton()}
				</TableBody>
			</Table>
		</Box>
	}

	function SegmentItemStats(props: {
		title: string,
		items: AdminTotalItemCountResponse[],
		soldItemsByYear: AdminItemSoldByDateResponse[],
		totalValueOfItemsForSale?: number}) {

		const thisYear = new Date().getFullYear();
		const lastYear = thisYear - 1;
		const twoYearsAgo = thisYear - 2;

		const totalItemsSoldThisYear = props.soldItemsByYear
			.filter(item => item.date.getFullYear() === thisYear)
			.map(convertDataToStat);

		const totalItemsSoldLastYear = props.soldItemsByYear
			.filter(item => item.date.getFullYear() === lastYear)
			.map(convertDataToStat);

		const totalItemsSoldTwoYearsAgo = props.soldItemsByYear
			.filter(item => item.date.getFullYear() === twoYearsAgo)
			.map(convertDataToStat)

		let mymap = new Map<string, string>();
		props.items.forEach(element => {
			mymap = mymap.set(element.title, element.count.toLocaleString())
		});

		mymap.set("Total value of items for sale", props.totalValueOfItemsForSale?.toLocaleString() ?? "-");

		const graphStats = [
			{
				title: thisYear.toString(),
				data: totalItemsSoldThisYear
			},
			{
				title: lastYear.toString(),
				data: totalItemsSoldLastYear
			},
			{
				title: twoYearsAgo.toString(),
				data: totalItemsSoldTwoYearsAgo
			}
		];

		return <StatsGraphTableComponent
			tableTitle={props.title}
			tableStats={mymap}
			variant="small"
			graphTitle="Sold items past week the last 3 years"
			graphStats={[...graphStats]}
			graphTicks={30}
			graphFormat={"dd/MM"}
			graphTotalDays={7}
		/>

	}

	return <Box
		display="flex"
		flexDirection="row"
	>
		{stats == null
			? <>
				<RenderSkeleton />
				<RenderSkeleton />
				<RenderSkeleton />
			</>
			: <>
				<SegmentItemStats title="Kids" items={stats.kids.totalCounts} soldItemsByYear={stats.kids.itemSoldByDate} totalValueOfItemsForSale={totalValueItemsKids?.totalPriceInHundreds} />
				<SegmentItemStats title="Women" items={stats.women.totalCounts} soldItemsByYear={stats.women.itemSoldByDate} totalValueOfItemsForSale={totalValueItemsWomen?.totalPriceInHundreds} />
				<SegmentItemStats title="Home" items={stats.home.totalCounts} soldItemsByYear={stats.home.itemSoldByDate} totalValueOfItemsForSale={totalValueItemsHome?.totalPriceInHundreds} />
			</>}
	</Box>
}

function convertDataToStat(item: AdminItemSoldByDateResponse): { x: Date; y1: number; } {
	return ({
		x: adjustYearOfDateToThisYear(item),
		y1: item.count
	});
}

function adjustYearOfDateToThisYear(item: AdminItemSoldByDateResponse): Date {
	//we need to adjust all dates to be the current year, so that they are at the same point on the X axis.
	return new Date(
		new Date().getFullYear(),
		item.date.getMonth(),
		item.date.getDate());
}
