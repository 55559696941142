import { adminClient } from "@api/admin/AdminClient";
import { withAdminMenuLayout } from "@components/admin/Decorators";
import ItemsOverviewPageStatsComponent from "@components/admin/items/overview/ItemsOverviewPageStatsComponent";
import PageTitle from "@components/admin/PageTitle";
import { queryApi } from "@hooks/api";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useState } from "react";

export default withAdminMenuLayout(function ItemsOverViewPageComponent() {
  const [listOfCountries] = queryApi(
    async (options) => await adminClient().adminUserAddressListOfValidCountriesGet(options), 
    []);

  const [country, setCountry] = useState("all");

  function handleChange(event: React.ChangeEvent<{ value: unknown }>) {
    setCountry(event.target.value as string);
  }

  const padding = 2;
  return <>
    <PageTitle title="Items">
    </PageTitle>
    <Box
      display="flex"
      flexDirection="reverse-row"
      alignItems="center"
      flexWrap="nowrap"
      justifyContent="flex-end"
      padding={2}
    >
      <Box>
        <FormControl
          fullWidth={true} style={{
            minWidth: "100px"
          }}>
          <InputLabel>
            Countries
            </InputLabel>
          <Select
            native={false}
            value={country}
            onChange={(e) => handleChange(e)}
          >
            <MenuItem value="all">
              All
                </MenuItem>
            {listOfCountries && listOfCountries.countries?.map((country, index) => {
              return <MenuItem value={country.toUpperCase()} key={index}>
                {country}
              </MenuItem>
            })}
          </Select>
        </FormControl>
      </Box>
    </Box>
    <Box pb={padding}>
      <ItemsOverviewPageStatsComponent country={country} />
    </Box>
  </>
});